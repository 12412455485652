const backdrop = document.createElement("div");
backdrop.classList.add('fixed', 'top-0', 'right-0', 'bottom-0', 'left-0', 'bg-black/50', 'z-40')
let activeModal

function openModal(evt) {
  activeModal = document.getElementById(evt.target.dataset.modalTarget)

  // display modal
  activeModal.classList.remove('hidden')
  activeModal.ariaHidden = "false"

  // add backdrop
  document.body.appendChild(backdrop)

  // disable scrolling
  document.body.style.overflow = "hidden"
}

function closeModal(evt) {
  if (!activeModal) return

  // hide modal
  activeModal.classList.add('hidden')
  activeModal.ariaHidden = "true"

  // remove backdrop
  document.body.removeChild(backdrop)

  // enable scrolling
  document.body.style.overflow = "auto"

  // pause youtube videos
  activeModal
    .querySelectorAll('iframe[src*="youtube"]')
    .forEach(
      el => el.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
    )

  // clean up
  activeModal = null
}

document.addEventListener("DOMContentLoaded", function (event) {
  document
    .querySelectorAll("[data-modal-target]")
    .forEach(el => el.addEventListener("click", openModal))

  document
    .querySelectorAll("[data-modal-hide]")
    .forEach(el => el.addEventListener("click", closeModal))

  backdrop.addEventListener("click", closeModal)

  document.addEventListener("keyup", evt => {
    if (evt.key === "Escape") closeModal(evt)
  })
});
