/**
 * Structure needs to be like:
 *
 * <div class="ch24-contentCycler">
 *  <div class="ch24-contentCycler-slides">
 *      <div class="ch24-contentCycler-slide">
 *          {{ slide }}
 *      </div>
 *  </div>
 * </div>
 */
export function createDesktopCycler(container) {
  const cycleContent = function () {
    const activeSlide = container.querySelector(
      ".ch24-contentCycler-slide--active"
    );

    if (activeSlide) {
      activeSlide.classList.remove("ch24-contentCycler-slide--active");
    }

    const nextSlide =
      (activeSlide && activeSlide.nextElementSibling) ||
      container.querySelector(".ch24-contentCycler-slide");
    nextSlide.classList.add("ch24-contentCycler-slide--active");
  };

  setInterval(cycleContent, 6.5 * 1000); // 6,5sec
  cycleContent();
}


/* eslint-disable func-names */
/* eslint-disable no-use-before-define */
/**
 * Structure needs to be like:
 *
 * <div class="ch24-contentCycler">
 *  <div class="ch24-contentCycler-slides">
 *      <div class="ch24-contentCycler-slide" data-content-cycle-slide-index="_0_BASED_INDEX_">
 *          {{ slide }}
 *      </div>
 *  </div>
 *  <ol class="ch24-contentCycler-indicators">
 *      <li class="ch24-contentCycler-indicator"></li>
 *  </ol>
 * </div>
 *
 * The amount of slides needs to be the same as the indicators!
 */
export function createMobileCycler(container) {
  container
    .querySelector(".ch24-contentCycler-slide")
    .classList.add("ch24-contentCycler-slide--active");

  // Registering the touchstart event to save the previous X coordinate into its dataset
  container.addEventListener("touchstart", function (event) {
    event.target.dataset.touchstartX = event.changedTouches[0].screenX;
  });

  // Registering the touchend event to compare the previous X coordinate with the new one
  container.addEventListener("touchend", function (event) {
    const oldX = parseInt(event.target.dataset.touchstartX);
    const newX = event.changedTouches[0].screenX;

    delete event.target.dataset.touchstartX;

    const swiped = Math.abs(newX - oldX) >= 20;
    if (!swiped) {
      return;
    }

    const activeSlide =
      container.querySelector(".ch24-contentCycler-slide--active") ||
      container.querySelector(".ch24-contentCycler-slide");
    const slidesForward = newX - oldX < 0;
    const nextVisibleSlide = slidesForward
      ? activeSlide.nextElementSibling
      : activeSlide.previousElementSibling;

    if (!nextVisibleSlide) {
      // The currently active slide is the first/last of all slides
      return;
    }

    if (activeSlide) {
      getSlide(container, activeSlide).setInactive();
    }

    if (nextVisibleSlide) {
      getSlide(container, nextVisibleSlide).setActive();
    }
  });

  getSlide(
    container,
    container.querySelector(".ch24-contentCycler-slide")
  ).setActive();
}


function getSlide(container, slide) {
  function getIndicator() {
    const indicatorsContainer = container.querySelector(
      ".ch24-contentCycler-indicators"
    );

    if (!indicatorsContainer) {
      return null;
    }

    const slideIndex = parseInt(slide.dataset.contentCycleSlideIndex);
    return indicatorsContainer.children.item(slideIndex);
  }

  return {
    setActive() {
      slide.classList.add("ch24-contentCycler-slide--active");
      getIndicator()?.classList.add("ch24-contentCycler-indicator--active");
    },
    setInactive() {
      slide.classList.remove("ch24-contentCycler-slide--active");
      getIndicator()?.classList.remove("ch24-contentCycler-indicator--active");
    },
  };
}
