interface Window {
  share: (target: HTMLElement, url: string, title?: string) => Promise<void>;
}

function showSuccessfulSharingNotification(target: HTMLElement): void {
  target.classList.toggle("shared", true);

  setTimeout(() => {
    target.classList.toggle("shared", false);
  }, 3000);
}

function shareViaClipboard(
  target: HTMLElement,
  url: string,
  title?: string
): Promise<void> {
  // todo: clarify with stakeholders: add title to clipboard? or just url?
  //  before TOOLHR-1400, we were adding title to clipboard
  //  const text = `${title}: ${url}`;
  const text = `${url}`;
  return navigator.clipboard.writeText(text).then(() => {
    showSuccessfulSharingNotification(target);
  });
}

function shareViaNavigator(
  target: HTMLElement,
  url: string,
  title?: string,
  text?: string
): Promise<void> {
  return navigator
    .share({
      url,
      title,
      text,
    })
    .then(() => {
      showSuccessfulSharingNotification(target);
    })
    .catch(() => {
      return shareViaClipboard(target, url, title);
    });
}

function share(
  event: Event,
  target: HTMLElement,
  url: string,
  title?: string
): Promise<void> {
  event.preventDefault()
  if (navigator.share) {
    return shareViaNavigator(target, url, title);
  }

  return shareViaClipboard(target, url, title);
}

window.share = share;

export default share;
