(function () {
  document
    .querySelectorAll("[data-youtube-id]")
    .forEach((preview: HTMLElement) => {
      const {youtubeId} = preview.dataset;

      if (!youtubeId) {
        window.console.error("Missing youtube id");
      }

      preview.addEventListener("click", () => {
        // eslint-disable-next-line no-param-reassign
        preview.insertAdjacentHTML(
          "beforeend",
          `<iframe
                    src="https://www.youtube.com/embed/${youtubeId}?rel=0&controls=1&showinfo=0&autoplay=1&enablejsapi=1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    class="absolute top-0 left-0 w-full h-full"
                    allowfullscreen></iframe>`
        )
      });
    });
})();
