import $ from "jquery";

$(document).ready(() => {
  if (!document.querySelector('.ch24-horizontal-carousel__slides-container')) return
  const container = document.querySelector('.ch24-horizontal-carousel__slides-container'),
    nextArrow = $(".ch24-horizontal-carousel__next"),
    prevArrow = $(".ch24-horizontal-carousel__previous"),
    slider = new Slider(container, {
      nextArrow, prevArrow
    })

  nextArrow.click(evt => slider.slideRight())
  prevArrow.click(evt => slider.slideLeft())
  container.addEventListener('touchstart', evt => slider.touchStart(evt))
  container.addEventListener('touchend', evt => slider.touchEnd())
  container.addEventListener('touchmove', evt => slider.touchMove(evt))
})

class Slider {
  constructor(el, options) {
    this.sliderContainer = el
    this.slides = Array.from(this.sliderContainer.children)
    this.nextArrow = options.nextArrow
    this.prevArrow = options.prevArrow
    this.currentSlide = 0
    this.slides.at(this.currentSlide).classList.add("current")

    this.isDragging = false
    this.startXPos = 0
    this.startYPos = 0
    this.currentTranslate = 0
    this.prevTranslate = 0

    this.makeRightArrowVisible();
  }

  makeRightArrowVisible() {
    this.nextArrow.addClass("is-visible");
  }

  makeRightArrowNotVisible() {
    this.nextArrow.removeClass("is-visible");
  }

  makeLeftArrowNotVisible() {
    this.prevArrow.removeClass("is-visible");
  }

  makeLeftArrowVisible() {
    this.prevArrow.addClass("is-visible");
  }

  getTotalWidthCurrentAndSlidersAfter() {
    return this.slides.slice(this.currentSlide).reduce((a, b) => a + $(b).innerWidth(), 0);
  }

  isTotalSlidersWidthAfterCurrentSmallerThenCarouselContainer() {
    const distance = this.getTotalWidthCurrentAndSlidersAfter();
    const containerWidth = this.sliderContainer.clientWidth;

    return distance < containerWidth;
  }

  slideLeft() {
    if (this.isFirstSlideCurrent()) {
      return;
    }

    this.makePrevSlideCurrent();

    this.moveContainer(-this.getDistanceUntilCurrentSlide());
    this.prevTranslate = -this.getDistanceUntilCurrentSlide()

    if (this.isFirstSlideCurrent()) {
      this.makeLeftArrowNotVisible();
    }
    if (!this.isTotalSlidersWidthAfterCurrentSmallerThenCarouselContainer()) {
      this.makeRightArrowVisible();
    }
  }

  isFirstSlideCurrent () {
    return this.slides.at(0).classList.contains("current");
  }

  isLastSlideCurrent() {
    return this.slides.at(-1).classList.contains("current");
  }

  slideRight () {
    if (this.isLastSlideCurrent()) {
      return;
    }

    this.makeNextSlideCurrent();

    this.moveContainer(-this.getDistanceUntilCurrentSlide());
    this.prevTranslate = -this.getDistanceUntilCurrentSlide()

    if (!this.isFirstSlideCurrent()) {
      this.makeLeftArrowVisible();
    }

    if (this.isTotalSlidersWidthAfterCurrentSmallerThenCarouselContainer()) {
      this.makeRightArrowNotVisible();
    }
  }

  makePrevSlideCurrent() {
    if (this.currentSlide === 0) return
    this.currentSlide -= 1;
    this.slides.at(this.currentSlide + 1).classList.remove("current");
    this.slides.at(this.currentSlide).classList.add("current");
  }

  makeNextSlideCurrent() {
    if (this.currentSlide >= this.slides.length) return
    this.currentSlide += 1 ;
    this.slides.at(this.currentSlide - 1).classList.remove("current");
    this.slides.at(this.currentSlide).classList.add("current");
  }

  moveContainer(distance) {
    $(this.sliderContainer).css({
      transform: `translate(${distance}px)`,
    });
  }

  getDistanceUntilCurrentSlide() {
    return this.slides.slice(0, this.currentSlide).reduce((a, b) => a + $(b).innerWidth(), 0);
  }

  touchStart(event) {
    this.sliderContainer.classList.remove('transition-transform', 'duration-300')
    this.startXPos = getPositionX(event)
    this.startYPos = getPositionY(event)
    this.isDragging = true
  }

  touchMove(event) {
    if (Math.abs(this.startYPos - getPositionY(event)) > 30) {
      this.touchEnd() // do not slide when moving up & down
    }
    if (this.isDragging) {
      const currentPosition = getPositionX(event),
        movedBy = currentPosition - this.startXPos

      this.currentTranslate = this.prevTranslate + movedBy
      this.moveContainer(this.currentTranslate)
    }
  }

  touchEnd() {
    this.sliderContainer.classList.add('transition-transform', 'duration-300')
    this.isDragging = false

    // swiped further than the left slide
    if (this.currentTranslate > 0) {
      this.setSlide(0)
      return
    }

    let i, width
    for (i = 0; i < this.slides.length; i++) {
      width = this.slides.slice(0, i).reduce((accumulator, el) => accumulator + el.clientWidth, 0)
      if (width > -this.currentTranslate) break
    }

    // swiped further than the last slide
    if (i === this.slides.length) {
      this.setSlide(this.slides.length-1)
      return;
    }

    // transition after 1/4 of the slide is in / outside of view
    const movedBy = this.currentTranslate - this.prevTranslate

    // sliding to the left
    if (movedBy < 0) {
      if (width - this.slides[i].clientWidth * 3 / 4 < -this.currentTranslate) {
        // move to current
        this.setSlide(i)
      } else {
        // move to previous
        this.setSlide(i-1)
      }
    }
    // sliding to the right
    if (movedBy > 0) {
      if (width - this.slides[i].clientWidth / 5 < -this.currentTranslate) {
        this.setSlide(i)
      } else {
        this.setSlide(i-1)
      }
    }
  }

  setSlide(idx) {
    this.slides.at(this.currentSlide).classList.remove('current')
    this.currentSlide = idx
    this.slides[idx].classList.add("current");
    this.currentTranslate = - this.slides.slice(0, idx).reduce((accumulator, el) => accumulator + el.clientWidth, 0)
    this.moveContainer(this.currentTranslate)
    this.prevTranslate = this.currentTranslate
    if (this.isFirstSlideCurrent()) {
      this.makeLeftArrowNotVisible();
    }
    if (!this.isTotalSlidersWidthAfterCurrentSmallerThenCarouselContainer()) {
      this.makeRightArrowVisible();
    }
    if (!this.isFirstSlideCurrent()) {
      this.makeLeftArrowVisible();
    }
    if (this.isTotalSlidersWidthAfterCurrentSmallerThenCarouselContainer()) {
      this.makeRightArrowNotVisible();
    }
  }
}

function getPositionX(event) {
  return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX
}

function getPositionY(event) {
  return event.type.includes('mouse') ? event.pageY : event.touches[0].clientY
}
